<template>
    <!--
    Диалог ввода кода подтверджения
    -->
    <el-dialog
        class="code-confirmation-dialog"
        :visible.sync="isActive"
        append-to-body
        center
        :close-on-click-modal="false"
        :show-close="false"
        v-loading="codeIsSending">

        <template v-slot:title>
            <dom-title-closable value="Введите код подтвержения" @close="onCancel"/>
        </template>

        <el-row v-if="code_mode" class="pb-15 text" >
            Вам отправлен {{code_mode | code_mode_name}} с кодом для подтверждения. Введите его в поле ниже.
            Код действителен {{code_ttl}} секунд
        </el-row>

        <el-row class="pb-15 text" >
            <div v-if="needCountdown" class="next-attempt disabled">Отправить код повторно через {{timeToNext}} секунд</div>
            <a v-else class="next-attempt" href="#" @click="doRequestCode">Отправить код повторно</a>
        </el-row>

        <el-row>
            <dom-input v-model="code" placeholder="Код" icon-left="fas el-icon-fa-address-el-icon-circle-check"/>
        </el-row>
        <el-row>
            <el-col :span="4">
                <dom-button-accept label="Подтвердить" :disabled="!formIsReady" @click="onVerifyCode"/>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomInput from "@/views/elements/DomInput";
import DomTitleClosable from "@/views/elements/DomTitleClosable";

export default {
    props: {

        // Метод запроса кода.
        // Должен вернуть Promise c количеством секунд до слежующего запроса
        requestCode: {
            type: Function,
            required: true,
        },

        // Метод, который должен выполниться с подтверждением кодом,
        // Должен вернуть Promise
        applyCode: {
            type: Function,// function(code)
            required: true,
        },
    },
    mixins: [Toggleable],
    components: {DomTitleClosable, DomInput, DomButtonAccept, DomButton, DomTextarea},
    data() {
        return {
            code: null,
            code_ttl: null,
            code_mode: null,
            codeIsSending: true,

            timeToNext: null,
            countdownTimeout: null,
            now: null,
        }
    },

    computed: {
        formIsReady() {
            return !!this.code
        },

        needCountdown() {
            return this.next_attempt > this.now;
        },

    },

    destroyed() {
        this.stop()
    },

    methods: {

        __onToggleOn() {
            this.code = null;
            this.doRequestCode()
        },

        doRequestCode() {
            this.codeIsSending = true;
            const self = this
            // todo абстрагироваться от request_timeout
            this.requestCode().then(({request_timeout, codes_ttl, mode}) => {
                self.now = Math.floor(Date.now() / 1000)
                self.next_attempt = self.now + request_timeout
                self.code_mode = mode;
                self.code_ttl = codes_ttl[mode];
                self.codeIsSending = false;
                self.start()
            })
            .catch((error) => {
                console.error(error)
                this.codeIsSending = false;
                this.isActive = false;
            })
        },

        start() {
            if (this.needCountdown) {
                if (this.countdownTimeout)
                    clearTimeout(this.countdownTimeout);
                const self = this
                this.countdownTimeout = setInterval(() => {
                    self.now = Math.floor(Date.now() / 1000)
                    self.timeToNext = self.next_attempt - self.now;
                    if (self.timeToNext === 0)
                        self.stop()
                }, 400);
            } else {
                if (this.countdownTimeout) {
                    clearTimeout(this.countdownTimeout);
                    this.countdownTimeout = null
                }
            }
        },

        stop() {
            if (this.countdownTimeout)
                clearTimeout(this.countdownTimeout);
        },

        onCancel() {
            this.isActive = false;
        },

        onVerifyCode() {
            const loading = this.$dom_loading()
            this.applyCode(this.code)
            .then(()=>{
                this.isActive = false;
                this.codeIsSending = false;
            })
            .finally(() => {
                loading.close()
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.text {
    word-break: normal;
    white-space: normal;
}

</style>
